@import url(https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.form-wrapper {
  width: 500px;
  margin: 0 auto;
}

.App {
  text-align: center;
  margin-top: 30px;
}

nav {
  width: 100%;
  margin: 0 auto;
  background: #282c34;
  height: 70px;
  line-height: 70px;
}

nav ul li {
  display: inline;
  list-style-type: none;
  text-align: center;
  padding: 30px;
}

nav ul li a {
  margin: 50px 0;
  font-weight: bold;
  color: white;
  text-decoration: none;
}

nav ul li a:hover {
  color: white;
  text-decoration: none;
}

table {
  margin-top: 50px;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

